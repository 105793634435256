import logo from './logo.png';
import './App.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Thankyou() {

    const navigate = useNavigate()

    return (
        <div class="container">
            <div class="header">
                <img src={logo} alt="Logo" class="logo" />
            </div>
            <div class="content">
                <h1>Grazie per aver condiviso il tuo ricordo!</h1>
                <h2>La tua immagine è stata caricata con successo.</h2>
                <p><a href={"/"}>Carica un'altra immagine</a></p>
            </div>
        </div>
    )

};

export default Thankyou;