import logo from './logo.png';
import './App.css';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function App() {
  const [previewImage, setPreviewImage] = useState(null);
  const [desc, setDesc] = useState("Clicca qui per selezionare un'immagine")
  const scope = ['https://www.googleapis.com/auth/drive.file'];

  const token = useRef();
	const pickerApiLoaded = useRef();

	const { REACT_APP_CLIENT_ID, REACT_APP_PROJECT_ID, REACT_APP_DEVELOPER_KEY } =
		process.env;

	// 1. Load Google Auth & Picker APIs
	function showPickerDialog() {
		window.gapi.load('auth', { callback: onAuthApiLoad });
		window.gapi.load('picker', { callback: onPickerApiLoad });
	}

	// 2. Handle Auth Api load
	function onAuthApiLoad() {
		window.gapi.auth.authorize(
			{
				client_id: REACT_APP_CLIENT_ID,
				scope: scope,
				immediate: false,
			},
			handleAuthResult
		);
	}

	// 3. Handle Picker Api load
	function onPickerApiLoad() {
		pickerApiLoaded.current = true;
		// createPicker();
	}

	// 4. Handle Google Auth
	function handleAuthResult(authResult) {
		// console.log(authResult);
		if (authResult && !authResult.error) {
			token.current = authResult.access_token;
			createPicker();
		}
	}

	// 5. Create and render a Picker object for searching images.
	function createPicker() {
		if (pickerApiLoaded.current && token.current) {
			const uploadView =
				new window.google.picker.DocsUploadView().setIncludeFolders(true);

			const picker = new window.google.picker.PickerBuilder()
				.enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
				.setAppId(REACT_APP_PROJECT_ID)
				.setOAuthToken(token.current)
				.addView(uploadView)
				.setDeveloperKey(REACT_APP_DEVELOPER_KEY)
				.setCallback(pickerCallback)
				.build();
			picker.setVisible(true);
		}
	}

	// 6. Callback from drive after upload
	function pickerCallback(data) {
		if (data.action === window.google.picker.Action.PICKED) {
			console.log(true);
			setTimeout(() => {
				console.log(false);
			}, [3000]);
		}
	}

  const navigate = useNavigate()

  const handleImageUpload = (event) => {
    const input = event.target
    const objectUrl = URL.createObjectURL(input.files[0])
    setPreviewImage(objectUrl)
    setDesc("Immagine caricata: " + input.files[0].name)
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    showPickerDialog()
    //navigate("/thankyou")
  }

  return (
    <div className="container">
      <div className="header">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className="content">
        <h1>Cosa ti viene in mente quando pensi a me?</h1>
        <h2>Condividi con me una foto della serata o di un ricordo passato insieme</h2>
        <div className="upload-container" onClick={() => document.getElementById('fileInput').click()}>
          {desc}
        </div>
        <form id="uploadForm" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
          <input type="file" id="fileInput" name="image" accept="image/*" className="hidden" onChange={(e) => handleImageUpload(e)} />
          <br /><br />
          <button type="submit">Carica Immagine</button>
        </form>
        <img className='preview' src={previewImage}></img>
      </div>
    </div>
  );
}

export default App;
